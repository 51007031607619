.dynamic-width {
  width: calc(100% - 620px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
.text-truncate100 {
  text-align: center !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.text-truncate200 {
  text-align: left !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.text-truncate400 {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}

.active {
  cursor: pointer;
}
.page-num li a {
  cursor: pointer;
}
.page-num li a:hover {
  cursor: pointer;
}
.page-num li a.active {
  cursor: pointer;
}
.container {
  padding-top: 0px !important;
}
