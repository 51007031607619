.COMPLETE {
  display: inline-block;
  padding: 4px 8px !important;
  background-color: #5d88f1;
  color: #fff;
  border-radius: 4px;
}

.CREATE {
  display: inline-block;
  padding: 4px 8px !important;
  background-color: #32bf95;
  color: #fff;
  border-radius: 4px;
}

.RETURNED {
  display: inline-block;
  padding: 4px 8px !important;
  background-color: #515151;
  color: #fff;
  border-radius: 4px;
}

.REJECTED {
  display: inline-block;
  padding: 4px 8px !important;
  background-color: #ef6961;
  color: #fff;
  border-radius: 4px;
}

.HONGBO_ING {
  display: inline-block;
  padding: 4px 8px !important;
  background-color: #999999;
  color: #fff;
  border-radius: 4px;
}

.HONGBO_OK {
  display: inline-block;
  padding: 4px 8px !important;
  background-color: #ffac0b;
  color: #fff;
  border-radius: 4px;
}

.center-align-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dynamic-width {
  width: calc(100% - 620px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
.text-truncate100 {
  text-align: center !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.text-truncate200 {
  text-align: left !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.text-truncate400 {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}

.active {
  cursor: pointer;
}
.page-num li a {
  cursor: pointer;
}
.page-num li a:hover {
  cursor: pointer;
}
.page-num li a.active {
  cursor: pointer;
}
.container {
  padding-top: 0px !important;
}

.hoveredRow {
  background-color: #f7f7f6; /* 원하는 색상으로 변경하세요 */
}
